import DATA from '../assets/card6.jpg'
import BUSINESS from '../assets/card1.webp'
import UIUX from '../assets/card2.webp'
import Development from '../assets/card3.webp'
import QA from '../assets/card4.jpg'
import DEPLOYMENT from '../assets/card5.jpg'
const HowWeWorkData = [
    {
        img: BUSINESS,
        title: "BUSINESS ANALYSIS",
        desc: "We offer process, requirement, and operational analysis for clients to achieve their business goals."
    },
    {
        img: UIUX,
        title: "UI/UX DEVELOPMENT",
        desc: "We provide engaging and user-friendly digital experiences for the clients products or services."
    },
    {
        img: Development,
        title: "DEVELOPMENT",
        desc: "We provide innovative development solutions for a rapidly evolving world."
    },
    {
        img: QA,
        title: "TESTING AND QA",
        desc: "Our goal is to make our products work without fails."
    },
    {
        img: DEPLOYMENT,
        title: "DEPLOYMENT",
        desc: "We assist companies in the deployment and maintenance of applications."
    },
    {
        img: DATA,
        title: "DATA MANAGEMENT",
        desc: "We help to manage all the business data in the system the right way."
    },
    
    
]
export default HowWeWorkData