const EmployeeData = [
    {
        gender: "male",
        name: "Ikram Khan",
        position: "Full-Stack Python Developer",
        desc: "7+ years of experience in Full-Stack Development & Python & Django Development",
    },
    {
        gender: "male",
        name: "Muhammad Waseem",
        position: "Full-Stack Python Developer",
        desc: "5 years of experience as a python Django & Flask developer",
    },
    {
        gender: "male",
        name: "Ashan Tariq",
        position: "Sr. React Native Engineer",
        desc: "5+ years of experience in mobile application development",
    },
    {
        gender: "male",
        name: "Asif Kamal Hoti",
        position: "Program Lead",
        desc: "16+ year experience in Project management, Coordination and community development",
    },
    {
        gender: "male",
        name: "Ashan Khan",
        position: "Web Developer",
        desc: "5+ years of experience in core WordPress development",
    },
    {
        gender: "male",
        name: "Adeel Shahzad",
        position: "Digital Marketer",
        desc: "3+ years of experience in digital Marketing with the latest SEO Expertise",
    },
    {
        gender: "male",
        name: "Adnan",
        position: "3D Modelling",
        desc: "4+ years of experience in 3D Modelling and Rendering",
    },
    {
        name: "Gul-E-Rana",
        gender: "female",
        position: "WordPress Developer",
        desc: "2+ years of experience in WordPress development & Content writing",
    },
    {
        gender: "male",
        name: "Hasham Bashir",
        position: "Engineering Manager & Lead Front End",
        desc: "8+ years of experience in Engineering Management and Front End Development",
    },
    {
        gender: "male",
        name: "Waqas Khan",
        position: "Front-end Developer",
        desc: "3+ years of experience in React, Vue, API integration development.",
    },
    {
        gender: "male",
        name: "Muneeb Ahmed",
        position: "Front-end Developer",
        desc: "2+ years of experience in React, Vue, API integration development.",
    },
    {
        gender: "male",
        name: "Dawood Israr",
        position: "Backend Developer",
        desc: "3 years of experience in Node development, APIs & Rest APIs development.",
    },
    
    {
        gender: "male",
        name: "Muhammad Tashfeen",
        position: "Unity Developer",
        desc: "3+ years of experience in Unity Development",
    },
    {
        name: "Sana",
        gender: "female",
        position: "Graphic Designer",
        desc: "2 years of experience in Graphic Designing",
    },
    {
        gender: "male",
        name: "Muhammad Izaz",
        position: "Graphic Designer",
        desc: "1 + years of experience in Graphic Designing",
    }, 
    {
        gender: "male",
        name: "Yasir Akram",
        position: "Senior Software Developer",
        desc: "8+ years of experience in the IT development",
    },
    {
        gender: "male",
        name: "Muhammad Haris Khan",
        position: "React Native Engineer",
        desc: "2+ experience in React Native Development.",
    },
    {
        gender: "male",
        name: "Itesham",
        position: "Engineering Modelling Designer",
        desc: "2+ years of experience in Engineering Modelling",
    },
    {
        name: "Kalsoom Khan",
        gender: "female",
        position: "Accountant",
        desc: "2.5+ years of experience as an ACCA Accountant",
    },
    {
        gender: "male",
        name: "Sabahat Malik",
        position: "Product Analyst",
        desc: "2 years of experience working in and managing multi-functional team projects",
    },
    {
        name: "Hira Khan",
        gender: "female",
        position: "Research Analyst",
        desc: "2+ years of experience as a Research Analyst and Technical Content writer",
    },
    {
        gender: "male",
        name: "Musawir Ghani",
        position: "AutoCAD Designer",
        desc: "5+ years of experience in CAD Designs & Mechanical Model Designing",
    },
    {
        gender: "male",
        name: "Muhammad Kashif",
        position: "Digital Marketing Expert",
        desc: "3 years experience in Digital Marketing: Driving Success through Innovative Strategies and Data-Driven Insights.",
    },
    {
        gender: "male",
        name: "Mir Shahab",
        position: "QA Engineer",
        desc: "3+ years of experience in crowdtesting, Test automation, testing tools & wordpress development.",
    },
]
export default EmployeeData
