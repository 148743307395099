import React from 'react'

export default function Card(props) {
    return (
        <section>
            <div className="flex flex-col items-center justify-center bg-white p-5 mb-2 shadow rounded-lg " >
                <h2 className=" font-bold text-xl">{props.name}</h2>
                <h6 className="mt-2 text-sm font-medium">{props.position}</h6>
                <p className="text-xs text-gray-500 text-center mt-3">
                    {props.desc}
                </p>
            </div>
        </section>
    )
}
// data-aos="flip-right" data-aos-duration="1000"
