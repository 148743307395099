import React from 'react'
import Femflo from '../../assets/femflo.png'
import LMS from '../../assets/LMS.png'
import News from '../../assets/news.png'
import Staff from '../../assets/staff.png'

export default function Products() {

    return (
        <section>
            <div className='mt-5 mb-12 overflow-hidden'>
                <h1 className='file:font-bold text-4xl flex justify-center font-bold bg-CST md:mx-14 lg:mx-28 p-2 text-white' data-aos="flip-up" data-aos-duration="1000">OUR PRODUCTS </h1>
                <div className='grid xl:grid-cols-2 items-center py-14 gx-0'>
                    <div className='md:mx-24' data-aos="fade-right" data-aos-offset="239" data-aos-easing="ease-in-sine">
                        <h1 className='flex justify-center font-bold text-3xl'>FEMFLO</h1>
                        <div className='flex justify-center pt-5'>
                            <p className=' w-8/12 text-justify'>Female is Pakistan’s first easy-to-use mobile application that tracks and monitors females’ periods, fertility, and ovulation cycle. This app helps women with conceiving, birth control, timely reminders, and the regularity of period cycles.</p>
                        </div>
                    </div>
                    <div className='flex justify-center mt-14 xl:mt-0 xl:mr-10' data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <img src={Femflo} width={500} alt="MARTVERS" />
                    </div>
                </div>
                <div className='grid xl:grid-cols-2 items-center bg-gray-50 py-14 gx-0'>
                    <div className='flex justify-center mt-14 xl:mt-0 xl:ml-10 order-1' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <img src={LMS} width={500} alt="LMS" />
                    </div>
                    <div className='md:mx-24 xl:order-1' data-aos="fade-left" data-aos-offset="227" data-aos-easing="ease-in-sine">
                        <h1 className='flex justify-center font-bold text-2xl md:text-3xl'>LEARNING MANAGEMENT SYSTEM</h1>
                        <div className='flex justify-center pt-5'>
                            <p className=' w-8/12 text-justify'>Learning Management System (LMS) by Cyber Sync Technologies. This platform provides an intuitive and user-friendly interface for educators to manage and deliver their educational content.</p>
                        </div>
                    </div>
                </div>
                <div className='grid xl:grid-cols-2 items-center py-14 gx-0'>
                    <div className='md:mx-24' data-aos="fade-right" data-aos-offset="170" data-aos-easing="ease-in-sine">
                        <h1 className='flex justify-center font-bold text-3xl'>The News Wall Website</h1>
                        <div className='flex justify-center pt-5'>
                            <p className=' w-8/12 text-justify'>News wall is a state-of-the-art news agency website created by Cyber Sync Technologies. It offers a platform for users to stay updated with the latest news and events from around the world.</p>
                        </div>
                    </div>
                    <div className='flex justify-center mt-14 xl:mt-0 xl:mr-10' data-aos="fade-left" data-aos-offset="244" data-aos-easing="ease-in-sine">
                        <img src={News} width={500} alt="MARTVERS" />
                    </div>
                </div>
                <div className='grid xl:grid-cols-2 items-center bg-gray-50 py-14 gx-0'>
                    <div className='flex justify-center mt-14 xl:mt-0 xl:ml-10 order-1' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <img src={Staff} width={500} alt="LMS" />
                    </div>
                    <div className='md:mx-24 xl:order-1' data-aos="fade-left" data-aos-offset="227" data-aos-easing="ease-in-sine">
                        <h1 className='flex justify-center font-bold text-2xl md:text-3xl'>STAFF MANAGEMENT SYSTEM</h1>
                        <div className='flex justify-center pt-5'>
                            <p className='w-8/12 xl:w-10/12 text-justify'>Cyber Sync Technologies has created an efficient Staff Management System to enhance team management. Our services enhance communication, boost productivity, and maximize performance, guaranteeing that your team operates at its highest level.</p>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center mt-10'>
                    <a href='https://cybersynctech.com/our-technologies/' class="bg-transparent hover:bg-CST hover:text-white text-gray-900 font-bold py-3 px-8 rounded-full border-2 border-blue-400 shadow-xl">
                        Our Technologies
                    </a>
                </div>
            </div>
        </section>
    )
}
