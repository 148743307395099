import React from 'react'

export default function Company() {
    return (
        <section>
            <div className='mt-5 overflow-hidden'>
                <h1 className='font-bold text-3xl flex justify-center bg-CST p-2 text-white md:mx-14 lg:mx-28' data-aos="flip-up" data-aos-duration="2000">THE COMPANY</h1>
                <div className='flex justify-center mt-7' data-aos="fade-up" data-aos-duration="2000">
                    <p className='w-9/12 text-justify text-lg'>Cyber Sync Technologies is the first Female-Led Core Tech Company in the entire Entrepreneurial Ecosystem of KPK that offers innovative solutions to digital problems. With a deep understanding of modern technology and strong expertise in multiple business domains, we implement comprehensive projects and deliver high-end web, mobile and 3D solutions.</p>
                </div>
            </div>
        </section>
    )
}
