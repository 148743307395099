import React from 'react'
import AntiCyber from '../../assets/Anti-Cyber.jpg'
import ITTrainings from '../../assets/ITTrainings.jpg'
import HerEPrivacy from '../../assets/HerEPrivacy.jpg'

export default function Meetup() {

    return (
        <section>
            <div className='mt-5 mb-12 overflow-hidden'>
                <h1 className='file:font-bold text-3xl md:text-4xl flex justify-center font-bold bg-CST md:mx-14 lg:mx-28 p-2 text-white' data-aos="flip-up" data-aos-duration="1000">Awareness & Road Shows</h1>
                <div className='md:mx-28'>
                    <h1 className='py-3 font-bold text-2xl sm:text-3xl flex justify-center mt-5 bg-gray-100 md:mx-10 lg:mx-14 p-2 text-gray-700' data-aos="flip-up" data-aos-duration="1000">Anti-Cyber Bullying Awareness</h1>
                    <div className='flex justify-center mt-7' data-aos="fade-up" data-aos-duration="2000">
                        <p className='w-9/12 text-justify text-lg'>Anti Cyber Bullying Roadshows are events organized by Cyber Sync Technologies to raise awareness about cyberbullying and provide guidance on how to prevent and deal with it. These roadshows are specifically targeted towards women, who are often more vulnerable to cyberbullying and online harassment.</p>
                    </div>
                    <div className='xl:mx-28 xl:py-14'>
                        <div className='flex justify-center mt-14 xl:mt-0 mx-5 xl:mx-0 xl:mr-10' data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in-sine">
                            <img src={AntiCyber} width={500} alt="MARTVERS" />
                        </div>
                        <div className='flex mt-10 gap-7 items-baseline justify-around bg-CST p-5 text-white mx-5 md:mx-28 rounded-3xl' data-aos="fade-up" data-aos-duration="1000">
                            <div className='flex flex-col justify-center text-center'>
                                <h1 className='text-2xl font-semibold'>200+</h1>
                                <p>Women have been made aware</p>
                            </div>
                            <div className='flex flex-col justify-center text-center'>
                                <h1 className='text-2xl font-semibold'>50+</h1>
                                <p>Women across KP</p>
                            </div>
                            <div className='flex flex-col justify-center text-center'>
                                <h1 className='text-2xl font-semibold'>5+</h1>
                                <p>Roadshows</p>
                            </div>
                        </div>
                        <div className='md:mx-16' data-aos="fade-left" data-aos-offset="248" data-aos-easing="ease-in-sine" >
                            <div className='flex flex-col items-center pt-5'>
                                <h2 className='text-lg font-semibold pt-5'> Key Points of Roadshows:</h2>
                                <ul className='pt-3 w-9/12'>
                                    <li className='text-justify pt-3'>Participants learn about securing their social media accounts, identifying and reporting cyberbullies, and using privacy settings effectively.</li>
                                    <li className='text-justify pt-3'>The roadshows create a safe space for women to share their experiences and seek support.</li>
                                    <li className='text-justify pt-3'>Open discussions, questions and connecting with other women who have gone through similar experiences are encouraged.</li>
                                    <li className='text-justify pt-3'>By equipping women with the knowledge and tools to protect themselves online, the roadshows are helping to create a safer and more inclusive digital environment for everyone.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='md:mx-28 mt-10'>
                    <h1 className='py-3 font-bold text-2xl sm:text-3xl flex justify-center mt-5 bg-gray-100 md:mx-10 lg:mx-14 p-2 text-gray-700' data-aos="flip-up" data-aos-duration="1000">IT Trainings</h1>
                    <div className='flex justify-center mt-7' data-aos="fade-up" data-aos-duration="2000">
                        <p className='w-9/12 text-justify text-lg'>Cyber Sync Technologies is committed to promoting gender diversity and inclusivity in the field of information technology. To this end, the company offers IT trainings specifically targeted towards women who want to develop their skills and pursue careers in the tech industry.</p>
                    </div>
                    <div className='xl:mx-28 xl:py-14'>
                        <div className='flex justify-center mt-14 xl:mt-0 mx-5 xl:mx-0 xl:mr-10' data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in-sine">
                            <img src={ITTrainings} width={500} alt="MARTVERS" />
                        </div>
                        <div className='flex mt-10 gap-7 items-baseline justify-around bg-CST p-5 text-white md:mx-28 mx-5 rounded-3xl' data-aos="fade-up" data-aos-duration="1000">
                            <div className='flex flex-col justify-center text-center'>
                                <h1 className='text-2xl font-semibold'>100+</h1>
                                <p>Women trained</p>
                            </div>
                            <div className='flex flex-col justify-center text-center'>
                                <h1 className='text-2xl font-semibold'>40+</h1>
                                <p>Women across KP</p>
                            </div>
                            <div className='flex flex-col justify-center text-center'>
                                <h1 className='text-2xl font-semibold'>5+</h1>
                                <p>Trainings</p>
                            </div>
                        </div>
                        <div className='md:mx-16' data-aos="fade-left" data-aos-offset="248" data-aos-easing="ease-in-sine" >
                            <div className='flex flex-col items-center pt-5'>
                                <h2 className='text-lg font-semibold pt-5'> Key Points of Trainings:</h2>
                                <ul className='pt-3 w-9/12'>
                                    <li className='text-justify pt-3'>To promote gender diversity and inclusivity in the field of information technology.</li>
                                    <li className='text-justify pt-3'>Trainings on basic computer skills to advanced programming languages and software development tools.</li>
                                    <li className='text-justify pt-3'>Experienced instructors teach the trainings.</li>
                                    <li className='text-justify pt-3'>Focus on developing soft skills such as communication, teamwork, and problem-solving.</li>
                                    <li className='text-justify pt-3'>Flexible and accessible trainings, with options for both in-person and online learning.</li>
                                    <li className='text-justify pt-3'>To bridge the gender gap in tech industry.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='md:mx-28 mt-10'>
                    <h1 className='py-3 font-bold text-2xl sm:text-3xl flex justify-center mt-5 bg-gray-100 md:mx-10 lg:mx-14  p-2 text-gray-700' data-aos="flip-up" data-aos-duration="1000">Her E-Privacy Meetup</h1>
                    <div className='flex justify-center mt-7' data-aos="fade-up" data-aos-duration="2000">
                        <p className='w-9/12 text-justify text-lg'>Her E-Privacy Meet-Up is arranged by Cyber Sync Technologies for women to learn about the importance of networking, community building and promote the participation of women in the digital economy.</p>
                    </div>
                    <div className='xl:mx-28 xl:py-14'>
                        <div className='flex justify-center mt-14 xl:mt-0 mx-5 xl:mx-0 xl:mr-10' data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in-sine">
                            <img src={HerEPrivacy} width={500} alt="MARTVERS" />
                        </div>
                        <div className='flex mt-10 gap-7 items-baseline justify-around bg-CST p-5 text-white md:mx-28 mx-5 rounded-3xl' data-aos="fade-up" data-aos-duration="1000">
                            <div className='flex flex-col justify-center text-center'>
                                <h1 className='text-2xl font-semibold'>300+</h1>
                                <p>Women Attendees</p>
                            </div>
                            <div className='flex flex-col justify-center text-center'>
                                <h1 className='text-2xl font-semibold'>30+</h1>
                                <p>Speakers</p>
                            </div>
                            <div className='flex flex-col justify-center text-center'>
                                <h1 className='text-2xl font-semibold'>2+</h1>
                                <p>Meetups</p>
                            </div>
                        </div>
                        <div className='md:mx-16' data-aos="fade-left" data-aos-offset="248" data-aos-easing="ease-in-sine" >
                            <div className='flex flex-col items-center pt-5'>
                                <h2 className='text-lg font-semibold pt-5'> Key Points of Trainings:</h2>
                                <ul className='pt-3 w-9/12'>
                                    <li className='text-justify pt-3'>The event creates a safe space for women to share their experiences and challenges in the digital world.</li>
                                    <li className='text-justify pt-3'>The event features experienced speakers and industry experts who share their insights and knowledge.</li>
                                    <li className='text-justify pt-3'>Participants learn about the latest trends and developments in the field of e-privacy.</li>
                                    <li className='text-justify pt-3'>The event provides opportunities for networking, building connections with like-minded women, and also provides career opportunities.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
