import React from 'react'
import MISSION from "../../assets/img2.webp"
import Vission from "../../assets/img3.webp"
export default function Mission() {
    return (
        <section>
            <div className='grid md:grid-cols-2 md:mx-14 lg:mx-28 mt-14 gap-5 overflow-hidden'>
                <div className='flex align-middle items-center justify-center'>
                    <div>
                        <h1 className='font-bold text-3xl flex justify-center bg-CST p-2 text-white' data-aos="flip-up" data-aos-duration="1000 ">OUR MISSION</h1>
                        <div className='flex justify-center mt-5' data-aos="zoom-in-right" data-aos-duration="1000">
                            <p className='w-9/12 text-justify text-lg'>Our mission is to help businesses to think and implement disruptive, innovative, and cutting-edge solutions for their relevance and growth.</p>
                        </div>
                    </div>
                </div>
                <div className='flex align-middle items-center justify-center' data-aos="zoom-in-left" data-aos-duration="1000" data-aos-offset="209">
                    <img src={MISSION} alt="" width={500} />
                </div>
            </div>
            <div className='grid md:grid-cols-2 md:mx-14 lg:mx-28 mt-10 gap-5 overflow-hidden'>
                <div className='flex align-middle items-center justify-center order-2 md:order-1' data-aos="zoom-in-right" data-aos-duration="1000" data-aos-offset="209">
                    <img src={Vission} alt="" width={500} />
                </div>
                <div className='flex align-middle items-center justify-center order-1'>
                    <div>
                        <h1 className='font-bold text-3xl flex justify-center bg-CST p-2 text-white' data-aos="flip-up" data-aos-duration="1000">OUR Vission</h1>
                        <div className='flex justify-center mt-5' data-aos="zoom-in-right" data-aos-duration="1000">
                            <p className='w-9/12 text-justify text-lg'>To be the company of choice in the provision of quality information, communication, tech services and timely innovative solutions.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
