import React from "react";
import Layout from "./Layout/Layout";
function App() {

  return (
    <div>
      <Layout />
    </div>
  );
}

export default App;
