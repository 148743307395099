import React from 'react'

export default function HowWeWorkCard(props) {
    return (
        <section>
            <div className='rounded-lg' data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                <div className='shadow-xl overflow-hidden'>
                    <img src={props.img} alt=" random imgee" className="hover:-translate-y-1 hover:scale-110  duration-300 w-full object-cover object-center " />
                </div>
                <div className="relative px-4 -mt-16  ">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h4 className="mt-1 text-center text-xl font-bold uppercase leading-tight truncate">{props.title}</h4>
                        <div className="mt-2 flex justify-center">
                            <p className='text-justify w-64'>{props.desc}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
