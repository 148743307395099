import React from 'react'
import Logo from '../../assets/Logo2.png'
import { TbMathGreater } from 'react-icons/tb'
import { AiFillHome } from 'react-icons/ai'
import { MdEmail } from 'react-icons/md'
import { ImPhone } from 'react-icons/im'
import { AiFillFacebook } from 'react-icons/ai'
import { AiFillInstagram } from 'react-icons/ai'
import { AiFillLinkedin } from 'react-icons/ai'
import { AiFillTwitterSquare } from 'react-icons/ai'
import { AiFillYoutube } from 'react-icons/ai'
export default function Footer() {
    return (
        <section>
            <div data-aos="fade-up" data-aos-duration="1000">
                <div className='bg-CST2 '>
                    <div className='hidden lg:block '>

                        <div className='grid lg:grid-cols-4 p-5 gap-5'>
                            <div className='flex justify-center items-center'>
                                <img src={Logo} alt="" className='h-28' />
                            </div>
                            <div className='flex justify-center'>
                                <div>
                                    <h1 className='text-lg font-bold p-4 text-center text-white'>QUICK LINKS</h1>
                                    <ul className='text-white flex flex-col'>
                                        <li className='my-2 flex items-center'><span className='mx-5 text-gray-200'><TbMathGreater /> </span> <a href="https://cybersynctech.com/about/">About</a></li>
                                        <li className='my-2 flex items-center'> <span className='mx-5'><TbMathGreater /> </span><a href="https://cybersynctech.com/web-portfolio/">Web Portfolio</a></li>
                                        <li className='my-2 flex items-center'> <span className='mx-5'><TbMathGreater /> </span><a href="https://cybersynctech.com/career/">Career</a></li>
                                        <li className='my-2 flex items-center'> <span className='mx-5'><TbMathGreater /> </span><a href="https://hermeetup.cybersynctech.com/?_gl=1%2Ayttr4z%2A_ga%2AMTQ3NDc4NzMxMC4xNjc3NTcyMzY4%2A_ga_Y7HK4LTETS%2AMTY3ODg3OTczMC4yNy4xLjE2Nzg4ODA2NDYuMC4wLjA.&_ga=2.133476427.453727111.1678703127-1474787310.1677572368">HER E-Privacy</a></li>
                                        <li className='my-2 flex items-center'> <span className='mx-5'><TbMathGreater /> </span><a href="https://cybersynctech.com/our-stories/">Stories</a></li>
                                        <li className='my-2 flex items-center'> <span className='mx-5'><TbMathGreater /> </span><a href="https://cybersynctech.com/contact/">Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='flex justify-center'>
                                <div>
                                    <h1 className='text-lg font-bold text-white p-4'>CONTACT US</h1>
                                    <ul className='text-white flex flex-col'>
                                        <li className='my-2 flex items-center'><span className='mx-5 text-xl '><MdEmail /> </span>info@cybersynctech.com</li>
                                        <li className='my-2 flex items-center'><span className='mx-5 text-xl '><ImPhone /> </span>+92 318 4976500</li>
                                        <li className='my-2 flex items-center text-justify text-sm'><span className='mx-5 text-xl'><AiFillHome /> </span><span className='w-8/12'>Khyber Pakhtunkhwa IT Board 134, Industrial Estate, Hayatabad, Peshawar, Khyber Pakhtunkhwa, Pakistan</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='flex justify-center'>
                                <div>
                                    <h1 className='text-lg font-bold text-white p-4 text-center'>FIND US ON SOCIAL MEDIA</h1>
                                    <ul className='text-white flex flex-col'>
                                        <li className='my-2 flex items-center'><span className='mx-5 text-xl '><AiFillFacebook /> </span><a href="https://www.facebook.com/cybersynctech">Facebook</a></li>
                                        <li className='my-2 flex items-center'> <span className='mx-5 text-xl'><AiFillInstagram /> </span><a href="https://mobile.twitter.com/cybersynctech">Instagram</a></li>
                                        <li className='my-2 flex items-center'> <span className='mx-5 text-xl'><AiFillLinkedin /> </span><a href="https://www.linkedin.com/company/cyber-sync-technologies/">LinkedIn</a></li>
                                        <li className='my-2 flex items-center'> <span className='mx-5 text-xl'><AiFillTwitterSquare /> </span><a href="https://mobile.twitter.com/cybersynctech">Twitter</a></li>
                                        <li className='my-2 flex items-center'> <span className='mx-5 text-xl'><AiFillYoutube /> </span><a href="https://www.youtube.com/@cybersynctechnologies">Youtube</a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='block lg:hidden'>
                        <div className='flex justify-center items-center'>
                            <img src={Logo} alt="" className='h-28' />
                        </div>
                    </div>
                    <div className='block lg:hidden'>

                        <div className='grid md:grid-cols-3 p-5 gap-5 '>
                            <div className='flex sm:justify-start md:justify-center'>
                                <div>
                                    <h1 className='text-lg font-bold p-4 text-center text-white'>QUICK LINKS</h1>
                                    <ul className='text-white flex flex-col'>
                                        <li className='my-2 flex items-center'><span className='mx-5 text-gray-200'><TbMathGreater /> </span> <a href="https://cybersynctech.com/about/">About</a></li>
                                        <li className='my-2 flex items-center'> <span className='mx-5'><TbMathGreater /> </span><a href="https://cybersynctech.com/web-portfolio/">Web Portfolio</a></li>
                                        <li className='my-2 flex items-center'> <span className='mx-5'><TbMathGreater /> </span><a href="https://cybersynctech.com/career/">Career</a></li>
                                        <li className='my-2 flex items-center'> <span className='mx-5'><TbMathGreater /> </span><a href="https://hermeetup.cybersynctech.com/?_gl=1%2Ayttr4z%2A_ga%2AMTQ3NDc4NzMxMC4xNjc3NTcyMzY4%2A_ga_Y7HK4LTETS%2AMTY3ODg3OTczMC4yNy4xLjE2Nzg4ODA2NDYuMC4wLjA.&_ga=2.133476427.453727111.1678703127-1474787310.1677572368">HER E-Privacy</a></li>
                                        <li className='my-2 flex items-center'> <span className='mx-5'><TbMathGreater /> </span><a href="https://cybersynctech.com/our-stories/">Stories</a></li>
                                        <li className='my-2 flex items-center'> <span className='mx-5'><TbMathGreater /> </span><a href="https://cybersynctech.com/contact/">Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='flex  sm:justify-start md:justify-center'>
                                <div>
                                    <h1 className='text-lg font-bold text-white p-4'>CONTACT US</h1>
                                    <ul className='text-white flex flex-col'>
                                        <li className='my-2 flex items-center'><span className='mx-5 text-xl '><MdEmail /> </span>info@cybersynctech.com</li>
                                        <li className='my-2 flex items-center'><span className='mx-5 text-xl '><ImPhone /> </span>+92 318 4976500</li>
                                        <li className='my-2 flex items-center text-justify text-sm'><span className='mx-5 text-xl'><AiFillHome /> </span><span className='w-8/12'>Khyber Pakhtunkhwa IT Board 134, Industrial Estate, Hayatabad, Peshawar, Khyber Pakhtunkhwa, Pakistan</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='flex  sm:justify-start md:justify-center'>
                                <div>
                                    <h1 className='text-lg font-bold text-white p-4 text-center'>FIND US ON SOCIAL MEDIA</h1>
                                    <ul className='text-white flex flex-col'>
                                        <li className='my-2 flex items-center'><span className='mx-5 text-xl '><AiFillFacebook /> </span><a href="https://www.facebook.com/cybersynctech">Facebook</a></li>
                                        <li className='my-2 flex items-center'> <span className='mx-5 text-xl'><AiFillInstagram /> </span><a href="https://mobile.twitter.com/cybersynctech">Instagram</a></li>
                                        <li className='my-2 flex items-center'> <span className='mx-5 text-xl'><AiFillLinkedin /> </span><a href="https://www.linkedin.com/company/cyber-sync-technologies/">LinkedIn</a></li>
                                        <li className='my-2 flex items-center'> <span className='mx-5 text-xl'><AiFillTwitterSquare /> </span><a href="https://mobile.twitter.com/cybersynctech">Twitter</a></li>
                                        <li className='my-2 flex items-center'> <span className='mx-5 text-xl'><AiFillYoutube /> </span><a href="https://www.youtube.com/@cybersynctechnologies">Youtube</a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div className='p-1 my-1'>
                    <div className='flex justify-center'>
                        <h1 className='text-sky-600 text-sm font-semibold'>© Copyright 2023 All Rights Reserved Cyber Sync Technologies</h1>
                    </div>
                </div>
            </div >
        </section>
    )
}
