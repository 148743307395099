import img1 from '../assets/choose1.webp'
import img2 from '../assets/choose2.webp'
import img3 from '../assets/choose3.webp'
import img4 from '../assets/choose4.webp'
const WhyChooseData = [
    {
        img: img1,
        title: "Innovative Digital Solutions",
    },
    {
        img: img2,
        title: "Cutting-Edge IT Services",
    },
    {
        img: img3,
        title: "Exceptional Customer Service",
    },
    {
        img: img4,
        title: "Transparent Execution",
    },
]
export default WhyChooseData