import React from 'react'
import Footer from '../Components/Footer/Footer'
import Awards from '../Page Components/HomePage/Awards'
import Charts from '../Page Components/HomePage/Charts'
import Company from '../Page Components/HomePage/Company'
import Hero from '../Page Components/HomePage/Hero'
import HowWeWork from '../Page Components/HomePage/HowWeWork'
import MeetTeam from '../Page Components/HomePage/MeetTeam'
import Meetup from '../Page Components/HomePage/Meetup'
import Mission from '../Page Components/HomePage/Mission'
import Products from '../Page Components/HomePage/Products'
import Projects from '../Page Components/HomePage/Projects'
import Services from '../Page Components/HomePage/Services'
import WhyChoose from '../Page Components/HomePage/WhyChoose'



export default function Layout() {

    return (
        <section>
            <Hero />
            <Company />
            <Mission />
            <HowWeWork />
            <Charts />
            <Services />
            <Projects />
            <Products />
            <Meetup />
            <MeetTeam />
            <WhyChoose />
            <Awards />
            <Footer />
        </section>
    )
}
