import React from 'react'
import { useSpring, animated } from '@react-spring/web'
import { useInView } from 'react-intersection-observer'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
const AnimatedCircularProgressbar = animated(CircularProgressbar)

export default function Charts(props) {
    const { ref, inView } = useInView({
        threshold: 1, // trigger when 50% of the progress bar is visible
        triggerOnce: true, // only trigger once
    })

    const { value } = useSpring({
        value: inView ? 90 : 0, // animate to 90 if in view, or 0 if not
        from: { value: 0 },
        config: { duration: 2000 },
    })
    return (
        <section>
            <div className='md:mx-14 lg:mx-28 mt-14 overflow-hidden '>
                <div className='grid lg:grid-cols-2'>
                    <div className='flex justify-center  bg-CST p-2 text-white' data-aos="flip-up" data-aos-duration="1000">
                        <h1 className='font-bold text-3xl'>ENGAGEMENT</h1>
                    </div>
                    <div className='flex gap-5 justify-center lg:hidden' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className='text-center mt-12'>
                            <h1 className='text-lg font-semibold'>TIME & MATERIAL</h1>
                            <p className='text-3xl font-bold' style={{ color: "#318CE7" }}>90%</p>
                        </div>
                        <div className='text-center mt-12'>
                            <h1 className='text-lg font-semibold'>FIXED PRICE</h1>
                            <p className='text-3xl font-bold' style={{ color: "#76ABDF" }}>10%</p>
                        </div>
                    </div>
                    <div style={{ marginBottom: 80 }} className=" flex justify-center lg:hidden " data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className='w-56'>
                            <div style={{ marginTop: 30, display: "flex", justifyContent: "center" }} className='flex justify-center'>
                                <AnimatedCircularProgressbar
                                    value={90}
                                    styles={buildStyles({
                                        pathColor: "#318CE7",
                                        trailColor: "#AFDBF5"
                                    })} />
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center lg:ml-12 bg-CST p-2 text-white' data-aos="flip-up" data-aos-duration="1000">
                        <h1 className='font-bold text-3xl'>METHODOLOGIES</h1>
                    </div>
                    <div className='flex justify-center gap-5 lg:hidden' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className='text-center mt-12'>
                            <h1 className='text-lg font-semibold'>SCRUM</h1>
                            <p className='text-3xl font-bold' style={{ color: "#318CE7" }}>90%</p>
                        </div>
                        <div className='text-center mt-12'>
                            <h1 className='text-lg font-semibold'>KANBAN</h1>
                            <p className='text-3xl font-bold' style={{ color: "#76ABDF" }}>10%</p>
                        </div>
                    </div>
                    <div style={{ marginBottom: 80 }} className=" flex justify-center lg:hidden " data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className='w-56'>
                            <div style={{ marginTop: 30, display: "flex", justifyContent: "center" }} className='flex justify-center'>
                                <AnimatedCircularProgressbar
                                    value={90}
                                    styles={buildStyles({
                                        pathColor: "#318CE7",
                                        trailColor: "#AFDBF5"
                                    })} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='grid lg:grid-cols-6 mt-5 gap-3'>
                    <div className='text-center mt-12 hidden lg:block' data-aos="fade-right" data-aos-easing="ease-in-sine">
                        <h1 className='text-lg font-semibold'>TIME & MATERIAL</h1>
                        <p className='text-3xl font-bold' style={{ color: "#318CE7" }}>90%</p>
                    </div>
                    <div style={{ marginBottom: 80 }} className="hidden lg:block" data-aos-offset="168" data-aos="fade-right" data-aos-easing="ease-in-sine">
                        <div style={{ marginTop: 30 }} ref={ref}>
                            <AnimatedCircularProgressbar
                                value={value}
                                styles={buildStyles({
                                    pathColor: "#318CE7",
                                    trailColor: "#AFDBF5"
                                })}
                            />
                            <div style={{ width: "130%", paddingRight: 30 }}>{props.children}</div>
                        </div>
                    </div>
                    <div className='text-center mt-12 hidden lg:block' data-aos="fade-right" data-aos-easing="ease-in-sine">
                        <h1 className='text-lg font-semibold'>FIXED PRICE</h1>
                        <p className='text-3xl font-bold' style={{ color: "#76ABDF" }}>10%</p>
                    </div>
                    <div className='text-center mt-12 hidden lg:block' data-aos="fade-left" data-aos-easing="ease-in-sine">
                        <h1 className='text-lg font-semibold'>SCRUM</h1>
                        <p className='text-3xl font-bold' style={{ color: "#318CE7" }}>90%</p>
                    </div>
                    <div style={{ marginBottom: 80 }} className='hidden lg:block' data-aos-offset="168" data-aos="fade-left" data-aos-easing="ease-in-sine">
                        <div style={{ marginTop: 30 }} ref={ref}>
                            <AnimatedCircularProgressbar
                                value={value}
                                styles={buildStyles({
                                    pathColor: "#318CE7",
                                    trailColor: "#AFDBF5"
                                })}
                            />
                            <div style={{ width: "130%", paddingRight: 30 }}>{props.children}</div>
                        </div>
                    </div>
                    <div className='text-center mt-12 hidden lg:block' data-aos="fade-left" data-aos-easing="ease-in-sine">
                        <h1 className='text-lg font-semibold'>KANBAN</h1>
                        <p className='text-3xl font-bold' style={{ color: "#76ABDF" }}>10%</p>
                    </div>

                </div>
            </div>
        </section>
    )
}
