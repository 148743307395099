import React from 'react'
import Martwers from '../../assets/martwers.webp'
import IRM from '../../assets/IRM.png'
import Sohail from '../../assets/sohail.png'
import Saif from '../../assets/saif.png'
import Batoor from '../../assets/batoor.webp'
import ERP from '../../assets/erp.webp'
import ReactPlayer from "react-player"
import Martwersgif1 from '../../assets/gif1.gif'
import Martwersgif2 from '../../assets/gif2.gif'

export default function Projects() {

    return (
        <section>
            <div className='mt-5 mb-12 gx-0 overflow-hidden'>
                <h1 className='file:font-bold text-4xl flex justify-center font-semibold bg-CST md:mx-14 lg:mx-28 p-2 text-white' data-aos="flip-up" data-aos-duration="1000">OUR PROJECTS </h1>
                <div className='grid xl:grid-cols-2 items-center mt-5 xl:-mt-10 gx-0'>
                    <div className='md:mx-24' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <h1 className='flex justify-center font-bold text-3xl'>MARTVERS</h1>
                        <div className='flex justify-center pt-5'>
                            <p className='w-8/12 text-justify'>Pakistan’s first AR-based 3D shopping mart that provides a hassle-free shopping experience and offers cost-effective products that you can buy from the convenience of your home.</p>
                        </div>
                    </div>
                    <div className='flex justify-center -mt-14 xl:mr-10' data-aos="fade-left" data-aos-offset="500" data-aos-easing="ease-in-sine">
                        <img src={Martwers} width={470} alt="MARTVERS" />
                    </div>
                </div>
                <div className='grid xl:grid-cols-2 items-center bg-gray-50 py-14 gx-0'>
                    <div className='mt-10 order-1 flex-col flex justify-center' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <div className='flex justify-center mb-4 mx-32 xl:ml-64 '>
                            <img src={Martwersgif1} width={230} alt="MARTVERS" />
                            <img src={Martwersgif2} width={230} alt="MARTVERS" className='mx-3' />
                        </div>
                        <div className='flex justify-center xl:ml-44 h-72 sm:h-72 mt-14 xl:mt-0 mx-5 md:mx-20 lg:mx-60 xl:mx-11'>
                            <ReactPlayer
                                className='react-player'
                                url="https://youtu.be/GXkElek1PTY"
                                playing
                                width="100%"
                                height="100%"
                                loop
                                muted
                                controls
                            />
                        </div>
                    </div>
                    <div className='md:mx-24 xl:order-1 mt-5' data-aos="fade-left" data-aos-offset="193" data-aos-easing="ease-in-sine">
                        <h1 className='flex justify-center font-bold text-3xl'>MARTVERS 3D APP</h1>
                        <div className='flex justify-center pt-5'>
                            <p className='w-8/12 text-justify'>MartVers Pakistan's first 3D shopping Mart, an online AR based grocery shop dedicated to lowering unnecessary shopping costs and making it easy and fun experience for all. Our target is to enhance customer satisfaction with the use of innovative technologies in supermarkets.</p>
                        </div>
                    </div>
                </div>
                <div className='grid xl:grid-cols-2 items-center py-14 gx-0'>
                    <div className='md:mx-24 xl:order-1' data-aos="fade-right" data-aos-offset="239" data-aos-easing="ease-in-sine">
                        <h1 className='flex justify-center font-bold text-3xl'>IRM</h1>
                        <div className='flex justify-center pt-5'>
                            <p className='w-8/12 text-justify'>IRM Group is a corporate service provider company that deals in communications, construction, education, and consulting. From development to content, we provided full fledge five working websites to match the requirements of the client.</p>
                        </div>
                    </div>
                    <div className='flex justify-center mt-14 xl:mt-0 xl:mr-10 order-1' data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <img src={IRM} width={500} alt="IRM" />
                    </div>
                </div>
                <div className='grid xl:grid-cols-2 items-center py-14 bg-gray-50 gx-0'>
                    <div className='flex justify-center mt-14 xl:mt-0 xl:ml-10 order-1 ' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <img src={Sohail} width={500} alt="MARTVERS" />
                    </div>
                    <div className='md:mx-24 xl:order-1' data-aos="fade-left" data-aos-offset="251" data-aos-easing="ease-in-sine">
                        <h1 className='flex justify-center font-bold text-3xl'>SOHAIL CARPETS</h1>
                        <div className='flex justify-center pt-5'>
                            <p className=' w-8/12 text-justify'>Sohail Carpets provides various top-notch rugs, mats, cushions, and prayer mats that can be personalized and delivered according to the client’s requirements. We designed and developed the website in accordance with the trending shade aesthetics and consumer demand.</p>
                        </div>
                    </div>
                </div>
                <div className='grid xl:grid-cols-2 items-center py-14 gx-0'>
                    <div className='md:mx-24 xl:order-1' data-aos="fade-right" data-aos-offset="213" data-aos-easing="ease-in-sine">
                        <h1 className='flex justify-center font-bold text-3xl'>SAIF GROUP</h1>
                        <div className='flex justify-center pt-5'>
                            <p className=' w-8/12 text-justify'>Saif group is a real estate marketing company. We designed and developed the website according to the trending color aesthetics and customer demand</p>
                        </div>
                    </div>
                    <div className='flex justify-center mt-14 xl:mt-0 xl:mr-10 order-1' data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <img src={Saif} width={500} alt="IRM" />
                    </div>
                </div>
                <div className='grid xl:grid-cols-2 items-center py-14 bg-gray-50 gx-0'>
                    <div className='flex justify-center mt-14 xl:mt-0 xl:ml-10 order-1' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <img src={Batoor} width={460} alt="MARTVERS" />
                    </div>
                    <div className='md:mx-24 xl:order-1' data-aos="fade-left" data-aos-offset="235" data-aos-easing="ease-in-sine">
                        <h1 className='flex justify-center font-bold text-3xl'>BATOOR</h1>
                        <div className='flex justify-center pt-5'>
                            <p className=' w-8/12 text-justify'>Batoor is an on-demand delivery marketplace which delivers all kinds of products and items to consumers’ doorstep within an hour. We are digitizing the local food and retail industry and providing on-demand delivery services to businesses and consumers.</p>
                        </div>
                    </div>
                </div>
                <div className='grid xl:grid-cols-2 items-center py-14 gx-0'>
                    <div className='md:mx-24 xl:order-1' data-aos="fade-right" data-aos-offset="262" data-aos-easing="ease-in-sine">
                        <h1 className='flex justify-center font-bold text-3xl'>ERP SYSTEM</h1>
                        <div className='flex justify-center pt-5'>
                            <p className=' w-8/12 text-justify'>CST Team is working on an ERP System for a Real Estate Company to integrate all of its departments i.e. accounts, sales, etc. into one centralized web application. Our System provides high-accuracy results in fast service which helps the team leads monitor their employee and the workflow of the organization.</p>
                        </div>
                    </div>
                    <div className='flex justify-center mt-14 xl:mt-0 xl:mr-20 order-1' data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <img src={ERP} width={500} alt="IRM" />
                    </div>
                </div>
                <div className='grid xl:grid-cols-2 items-center py-14 bg-gray-50 gx-0'>
                    <div className='flex justify-center xl:ml-44 h-72 sm:h-96 mt-14 xl:mt-0 mx-5 md:mx-20 lg:mx-60 xl:mx-0 order-2 lg:order-1' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                        <ReactPlayer
                            className='react-player'
                            url="https://youtu.be/viuYuGUo7aU"
                            playing
                            width="100%"
                            height="100%"
                            loop
                            muted
                            controls
                        />
                    </div>
                    <div className='md:mx-24 order-1' data-aos="fade-left" data-aos-offset="232" data-aos-easing="ease-in-sine">
                        <h1 className='flex justify-center font-bold text-3xl'>REAL ESTATE SOLUTION</h1>
                        <div className='flex justify-center pt-5'>
                            <p className=' w-8/12 text-justify'>Cyber Sync Technologies is revolutionizing the real estate industry by introducing cutting-edge Augmented Reality (AR) and Virtual Reality (VR) technology. Our technology allows real estate professionals to showcase properties to potential buyers in a more efficient and effective manner, ultimately increasing sales and streamlining the buying and selling process.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
