import React from 'react'
import Heroimg from '../../assets/img1.webp'
import Navbar from '../../Components/Navbar/Navbar'
import '../../Style/Hero.css'
export default function Hero() {
    return (
        <section>
            <article className="article">
                <picture className="picture">
                    <source media="(min-width: 0px)" srcSet={Heroimg} />
                    <img src={Heroimg} alt="background" className='' data-aos="zoom-in" data-aos-duration="2000" />
                </picture>
                <div className='header2'>
                    <Navbar />
                </div>
                <h1 className="header flex justify-center text-3xl sm:text-5xl text-white" data-aos="fade-down" data-aos-duration="2000"> <span className='text-black px-2'>Cyber Sync </span>Technologies</h1>
            </article>
        </section>
    )
}
