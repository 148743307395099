import React from 'react'
import HowWeWorkCard from '../../Components/Card/HowWeWorkCard'
import HowWeWorkData from '../../Data/HowWeWorkData'
export default function HowWeWork() {
    return (
        <section>
            <div className='mt-10 overflow-hidden'>
                <h1 className='font-bold text-3xl flex justify-center bg-CST md:mx-14 lg:mx-28 p-2 text-white' data-aos="flip-up" data-aos-duration="1000">How We Work</h1>
                <div className="grid sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 antialiased text-gray-900 gap-14 mx-5 md:mx-20 lg:mx-32 my-8">
                    {
                        HowWeWorkData.map((data,index) => {
                            return <HowWeWorkCard
                                key={index}
                                img={data.img}
                                title={data.title}
                                desc={data.desc}
                            />
                        })

                    }
                </div>
            </div>
        </section>
    )
}
