import React, { useEffect, useState } from 'react'
import Logo from '../../assets/Logo.webp'
export default function Navbar() {
    const [open, setOpen] = React.useState(false);
    const [flyer, setFlyer] = React.useState(false);
    function handleClick() {
        setFlyer(!flyer);
    }
    function handleClick2() {
        setOpen(!open);
    }
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navbarClass = ` ${scrollPosition > 0 ? ' fixed w-full bg-white z-10 shadow-lg transition-all duration-500 ' : 'bg-transparent relative transition-all duration-500'}`

    return (
        <section>
            <div className={`${navbarClass} `}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6">
                    <div className="flex justify-between items-center  py-6 md:justify-between">
                        <div className="flex justify-start lg:w-0 lg:flex-1">
                            <a href="https://cybersynctech.com/">
                                <span className="sr-only">Workflow</span>
                                <img
                                    className="w-32 md:w-48 lg:h-7 lg:w-32 xl:w-52 xl:h-8 "
                                    src={Logo}
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="-mr-2 -my-2 lg:hidden">
                            <button
                                type="button" className="bg-transparent rounded-md p-2 inline-flex items-center justify-center text-gray-900  focus:outline-none " onClick={handleClick2}>
                                <span className="sr-only">Open menu</span>
                                <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            </button>
                        </div>
                        <nav className="hidden lg:flex space-x-10">
                            <a
                                href="https://cybersynctech.com/"
                                className="text-base font-medium text-gray-900 hover:text-gray-900"
                            >
                                Home
                            </a>
                            <a
                                href="https://cybersynctech.com/about/"
                                className="text-base font-medium text-gray-900 hover:text-gray-900"
                            >
                                About Us
                            </a>
                            <div className="relative">
                                <button
                                    type="button"
                                    className="group bg-transparent rounded-md text-gray-900 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none pb-8'
"
                                    onClick={handleClick}
                                >
                                    <span>Services</span>
                                    <svg
                                        className={
                                            flyer === true
                                                ? "transform rotate-180 ml-2 h-5 w-5 text-gray-900 group-hover:text-gray-900 transition ease-out duration-200"
                                                : "transform rotate-0 transition ease-out duration-200 ml-2 h-5 w-5 text-gray-900 group-hover:text-gray-900"
                                        }
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                                <div
                                    onMouseLeave={() => setFlyer(false)}
                                    className={
                                        flyer
                                            ? " opacity-100 translate-y-0 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-80 max-w-sm sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                                            : " opacity-0 pointer-events-none translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-sm sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                                    }
                                >
                                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                            <a
                                                href="https://cybersynctech.com/software-development/"
                                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                            >
                                                <svg
                                                    className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    aria-hidden="true"
                                                >

                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M2.25 15a4.5 4.5 0 004.5 4.5H18a3.75 3.75 0 001.332-7.257 3 3 0 00-3.758-3.848 5.25 5.25 0 00-10.233 2.33A4.502 4.502 0 002.25 15z" />
                                                </svg>
                                                <div className="ml-4">
                                                    <p className="text-base font-medium text-gray-900">
                                                        Software Development
                                                    </p>
                                                </div>
                                            </a>
                                            <a
                                                href="https://cybersynctech.com/web-development-in-peshawar/"
                                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                            >
                                                <svg
                                                    className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
                                                </svg>

                                                <div className="ml-4">
                                                    <p className="text-base font-medium text-gray-900">
                                                        Web Development
                                                    </p>
                                                </div>
                                            </a>
                                            <a
                                                href="https://cybersynctech.com/3d-modeling/"
                                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                            >
                                                <svg
                                                    className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
                                                </svg>
                                                <div className="ml-4">
                                                    <p className="text-base font-medium text-gray-900">
                                                        3D Modeling
                                                    </p>
                                                </div>
                                            </a>
                                            <a
                                                href="https://cybersynctech.com/bpo-services/"
                                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                            >
                                                <svg
                                                    className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                                    />
                                                </svg>
                                                <div className="ml-4">
                                                    <p className="text-base font-medium text-gray-900">
                                                        BPO Services
                                                    </p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a
                                href="https://cybersynctech.com/our-technologies/"
                                className="text-base font-medium text-gray-900 hover:text-gray-900"
                            >
                                Technologies
                            </a>
                            <a
                                href="https://hermeetup.cybersynctech.com/?_ga=2.125025103.453727111.1678703127-1474787310.1677572368&_gl=1%2A14zf1pa%2A_ga%2AMTQ3NDc4NzMxMC4xNjc3NTcyMzY4%2A_ga_Y7HK4LTETS%2AMTY3ODg3OTczMC4yNy4xLjE2Nzg4Nzk5NzguMC4wLjA."
                                className="text-base font-medium text-gray-900 hover:text-gray-900"
                            >
                                HER E-Privacy
                            </a>
                            <a
                                href="https://cybersynctech.com/career/"
                                className="text-base font-medium text-gray-900 hover:text-gray-900"
                            >
                                Career
                            </a>
                            <a
                                href="https://cybersynctech.com/our-stories/"
                                className="text-base font-medium text-gray-900 hover:text-gray-900"
                            >
                                Stories
                            </a>
                            <a
                                href="https://cybersynctech.com/contact/"
                                className="text-base font-medium text-gray-900 hover:text-gray-900"
                            >
                                Contact Us
                            </a>
                        </nav>
                    </div>
                </div>
                <div
                    className={
                        open
                            ? "opacity-100 scale-100 transition ease-out duration-200 absolute top-0 inset-x-0 p-2 transform origin-top-right lg:hidden z-10"
                            : "opacity-0 scale-95 absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden "
                    }
                >
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                        <div className="pt-5 pb-6 px-5">
                            <div className="flex items-center justify-between">
                                <div>
                                    <img
                                        className="h-8 w-auto"
                                        src={Logo}
                                        alt="Workflow"
                                    />
                                </div>
                                <div className="-mr-2">
                                    <button
                                        type="button"
                                        className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none "
                                        onClick={() => setOpen(!open)}
                                    >
                                        <span className="sr-only">Close menu</span>
                                        <svg
                                            className="h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="py-6 px-5 space-y-6">
                            <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                                <a
                                    href="https://cybersynctech.com/"
                                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                                >
                                    Home
                                </a>
                                <a
                                    href="https://cybersynctech.com/about/"
                                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                                >
                                    About Us
                                </a>
                                <div className="relative">
                                    <button
                                        type="button"
                                        className="group bg-transparent rounded-md text-gray-900 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none pb-8'
"
                                        onClick={handleClick}
                                    >
                                        <span>Services</span>
                                        <svg
                                            className={
                                                flyer === true
                                                    ? "transform rotate-180 ml-2 h-5 w-5 text-gray-900 group-hover:text-gray-900 transition ease-out duration-200"
                                                    : "transform rotate-0 transition ease-out duration-200 ml-2 h-5 w-5 text-gray-900 group-hover:text-gray-900"
                                            }
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </button>
                                    <div
                                        onMouseLeave={() => setFlyer(false)}
                                        className={
                                            flyer
                                                ? " opacity-100 translate-y-0 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-80 max-w-sm sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                                                : " opacity-0 pointer-events-none translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-sm sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                                        }
                                    >
                                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                                <a
                                                    href="https://cybersynctech.com/software-development/"
                                                    className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                                >
                                                    <svg
                                                        className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        aria-hidden="true"
                                                    >

                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M2.25 15a4.5 4.5 0 004.5 4.5H18a3.75 3.75 0 001.332-7.257 3 3 0 00-3.758-3.848 5.25 5.25 0 00-10.233 2.33A4.502 4.502 0 002.25 15z" />
                                                    </svg>
                                                    <div className="ml-4">
                                                        <p className="text-base font-medium text-gray-900">
                                                            Software Development
                                                        </p>
                                                    </div>
                                                </a>
                                                <a
                                                    href="https://cybersynctech.com/web-development-in-peshawar/"
                                                    className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                                >
                                                    <svg
                                                        className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
                                                    </svg>

                                                    <div className="ml-4">
                                                        <p className="text-base font-medium text-gray-900">
                                                            Web Development
                                                        </p>
                                                    </div>
                                                </a>
                                                <a
                                                    href="https://cybersynctech.com/3d-modeling/"
                                                    className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                                >
                                                    <svg
                                                        className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
                                                    </svg>
                                                    <div className="ml-4">
                                                        <p className="text-base font-medium text-gray-900">
                                                            3D Modeling
                                                        </p>
                                                    </div>
                                                </a>
                                                <a
                                                    href="https://cybersynctech.com/bpo-services/"
                                                    className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                                >
                                                    <svg
                                                        className="flex-shrink-0 h-6 w-6 text-indigo-600"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                                        />
                                                    </svg>
                                                    <div className="ml-4">
                                                        <p className="text-base font-medium text-gray-900">
                                                            BPO Services
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a
                                    href="https://cybersynctech.com/our-technologies/"
                                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                                >
                                    Technologies
                                </a>
                                <a
                                    href="https://hermeetup.cybersynctech.com/?_ga=2.158957439.453727111.1678703127-1474787310.1677572368&_gl=1%2A1fpmoln%2A_ga%2AMTQ3NDc4NzMxMC4xNjc3NTcyMzY4%2A_ga_Y7HK4LTETS%2AMTY3ODg3OTczMC4yNy4xLjE2Nzg4ODAxNzUuMC4wLjA."
                                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                                >
                                    HER E-Privacy
                                </a>
                                <a
                                    href="https://cybersynctech.com/career/"
                                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                                >
                                    Career
                                </a>
                                <a
                                    href="https://cybersynctech.com/our-stories/"
                                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                                >
                                    Stories
                                </a>
                                <a
                                    href="https://cybersynctech.com/contact/"
                                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                                >
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

