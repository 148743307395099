import React from 'react'

export default function ServiceImgCard(props) {
    return (
        <section>
            <div className='shadow-lg p-1' data-aos={props.AOS} data-aos-offset="300" data-aos-easing="ease-in-sine">
                <h1 className='flex justify-center font-semibold text-3xl py-8 underline'>{props.title}</h1>
                <img src={props.img} width={560} alt={props.title} />
            </div>
        </section>
    )
}
