import React from 'react'

export default function WhyChooseCard(props) {
    return (
        <section>
            <div className='flex justify-center align-middle flex-col items-center ' data-aos="fade-down" data-aos-duration="1000">
                <img src={props.img} width={props.width} alt="" />
                <p className='pt-3 text-center'>{props.title}​</p>
            </div>
        </section>
    )
}
