import React from 'react'
import ImageCard from '../../Components/Card/ImageCard'
import WhyChooseData from '../../Data/WhyChooseData'
export default function WhyChoose() {
    return (
        <section>
            <div className='my-20 overflow-hidden'>
                <h1 className='font-bold text-3xl flex justify-center bg-CST md:mx-14 lg:mx-28  p-2 text-white' data-aos="flip-up" data-aos-duration="1000">Why Choose Us</h1>
                <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:mx-28 lg:mx-32 mt-10 gap-10'>
                    {
                        WhyChooseData.map((data, index) => {
                            return <ImageCard
                                key={index}
                                img={data.img}
                                width={100}
                                title={data.title}
                            />
                        })
                    }
                </div>
            </div>
        </section>
    )
}
