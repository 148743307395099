import React from 'react'
import Male from '../../assets/male.png'
import Female from '../../assets/female.png'
import Card from '../../Components/Card/EmployeeCard'
import EmployeeData from '../../Data/EmployeeData'

export default function MeetTeam() {

    return (
        <section>
            <div className='mt-5 mb-12 overflow-hidden'>
                <h1 className='file:font-bold text-4xl flex justify-center font-bold bg-CST md:mx-14 lg:mx-28 p-2 text-white' data-aos="flip-up" data-aos-duration="1000">MEET OUR TEAM</h1>
                <div className='grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mx-5 md:mx-28 lg:mx-32 gap-5 mt-5'>
                    {
                        EmployeeData.map((data) => {
                            if (data.gender === 'female') {
                                return <Card
                                    key={data.name}
                                    img={Female}
                                    name={data.name}
                                    position={data.position}
                                    desc={data.desc}
                                />
                            }
                            else if (data.gender === 'male') {
                                return <Card
                                    key={data.name}
                                    img={Male}
                                    name={data.name}
                                    position={data.position}
                                    desc={data.desc}
                                />
                            }
                            return null;
                        })
                    }
                </div>
            </div>
        </section>
    )
}
