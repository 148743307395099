import React from 'react'
import Frontend from '../../assets/frontend.png'
import Backend from '../../assets/backend.png'
import Database from '../../assets/database.png'
import Modeling from '../../assets/3dModeling.png'
import CROSSPLATFORM from '../../assets/CrossPlatform.png'
import NATIVE from '../../assets/Native.png'
import ServiceImgCard from '../../Components/Card/ServiceImgCard'
export default function Services() {
    return (
        <section>
            <div className='md:mx-14 lg:mx-28 mt-5 mb-12 overflow-hidden'>
                <h1 className='py-2 file:font-bold text-4xl flex justify-center font-bold bg-CST text-white' data-aos="flip-up" data-aos-duration="1000">OUR <span className='px-2  text-white'> SERVICES </span></h1>
                <h1 className='py-3 font-bold text-2xl sm:text-3xl flex justify-center mt-5 bg-gray-100 md:mx-10 lg:mx-14 p-2 text-gray-700' data-aos="flip-up" data-aos-duration="1000">SOFTWARE DEVELOPMENT</h1>
                <div className='flex justify-center mt-5 gap-5 mx-5 flex-col lg:flex-row'>
                    <ServiceImgCard AOS={"fade-right"} title={"FRONTEND"} img={Frontend} />
                    <ServiceImgCard AOS={"fade-left"} title={"BACKEND"} img={Backend} />
                </div>
                <div className='flex justify-center mt-12 gap-5 mx-5 flex-col lg:flex-row'>
                    <ServiceImgCard AOS={"fade-right"} title={"DATABASE"} img={Database} />
                    <ServiceImgCard AOS={"fade-left"} title={"3D MODELING"} img={Modeling} />
                </div>
                <h1 className='py-3 font-bold text-2xl sm:text-3xl flex justify-center mt-10 bg-gray-100 md:mx-10 lg:mx-14 p-2 text-gray-700' data-aos="flip-up" data-aos-duration="1000">MOBILE APPLICATION DEVELOPMENT</h1>
                <div className='flex justify-center mt-5 gap-5 mx-5 flex-col lg:flex-row mb-6'>
                    <ServiceImgCard AOS={"fade-right"} title={"CROSS-PLATFORM"} img={CROSSPLATFORM} />
                    <ServiceImgCard AOS={"fade-left"} title={"NATIVE"} img={NATIVE} />
                </div>
            </div>
        </section>
    )
}
