import Award1 from '../assets/award1.webp'
import Award2 from '../assets/award2.webp'
import Award3 from '../assets/award3.webp'
import Award4 from '../assets/award4.webp'
import Award5 from '../assets/award5.webp'

const AwardData = [
    {
        img: Award2,
        width:110,
    },
    {
        img: Award5,
        width:110,
    },    
    {
        img: Award4,
        width:110,
    },
    {
        img: Award1,
        width:150,
    },
    {
        img: Award3,
        width:130,
    },    
]
export default AwardData